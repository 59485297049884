div.footer {
  background-color: #091738;
  color: #ffffff;
  padding-bottom: 2rem
}

.footerTitle, .footerItem {
  color: #ffffff;
  margin: 0;
}

.footerItem {
  font-size: var(--p-size-3);
  font-weight: 300;
}

.footerTitle {
  font-weight: 600;
  text-transform: uppercase;
  font-size: var(--p-size-2);
  margin-bottom: 24px;
}

.slogan {
  color: #fff;
  font-size: 25px;
  font-weight: 400;
  max-width: 300px;
}

.footerButton {
  border: 2px solid var(--primary-orange);
  background: transparent;
  color: var(--primary-orange);
  padding: 0 24px;
  height: 48px;
  font-weight: 600;
  border-radius: var(--border-radius-2);
}

.footerButton:hover {
  background: transparent;
}

.logo {
  max-width: 150px;
}

div.copy {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  background: #04102D;
  color: #ffffff;
  font-size: var(--p-size-3);
}
.privacyText {
  margin-left: 4px;
  color: #ffffff;
  font-size: var(--p-size-3);
}
@media screen and (max-width: 768px) {
  .footer {
    text-align: center;
  }
}
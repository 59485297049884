.subHero h2 {
  font-size: var(--heading-size-1);
  color: var(--dark-blue);
  font-weight: 700;
}

.subHero h4 {
  font-size: var(--heading-size-5);
  color: var(--primary-blue);
  font-weight: 700;
  margin: 0;
}

.item {
  padding: 1rem 0 .75rem 4rem;
  border-radius: var(--border-radius-1);
  transition: all 0.6s ease-in-out;
  background: transparent;
}

.item.active {
  background: #ffffff;
}

.item h3 {
  font-size: var(--heading-size-2);
  color: var(--dark-blue);
  position: relative;
  font-weight: 700;
  margin-bottom: .5rem;
}

.item h3::before {
  content: "";
  border-radius: 4px;
  background: var(--primary-blue);
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -4px;
  margin-right: 20px;
  height: 8px;
  width: 30px;
  transition: all 0.4s ease-in-out;
}

.item.active h3::before {
  background: var(--primary-orange)
}

.item p {
  font-size: var(--p-size-2);
  color: var(--primary-text-color);
}

.layers {
  position: relative;
  max-width: 815px;
}

.layers::before {
  content: "";
  background: #ffffff;
  position: absolute;
  top: 12.5%;
  right: 12.5%;
  left: 12.5%;
  bottom: 12.5%;
  border-radius: 24px;
}

.layers > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.6s ease-in-out;
  opacity: 0;
  filter: blur(8px);
}

.layers > div.active {
  opacity: 1;
  filter: none;
}

.layers > div.base {
  position: relative;
  opacity: 1;
  filter: none;
}

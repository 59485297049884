.headerLogo,
.headerLogoSquared {
  display: inline-block;
  max-width: 150px;
  white-space: nowrap;
  opacity: 1;
  transition: all 0.25s ease-in-out;
}

.headerLogoSquared {
  position: absolute;
  left: 4px;
}

.logoWrapper {
  display: flex;
}

.login {
  font-weight: 700;
}

.header .begin {
  color: #ffffff;
  font-weight: 600;
  font-size: 0.9375rem;
}

.spacer {
  height: 98px;
  transition: all 0.5s ease-in-out;
}

.header {
  position: fixed;
  z-index: 99;
  padding-top: 6px;
  width: 100%;
  height: 98px;
  background: #ffffff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.12);
  transition: all 0.5s ease-in-out;
}

.innerFooter {
  margin: 0px 8vw;
}

.innerHeader {
  padding: 20px 0px;
}

.header.smallHeader .innerHeader {
  padding: 0px 0px;
}

.header.smallHeader .headerLogo {
  opacity: 0;
}

.header.smallHeader .headerLogoSquared {
  opacity: 1;
}

.header::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 6px;
  background: 6px solid var(--dark-blue);
  background: linear-gradient(
    359.5deg,
    rgba(249, 141, 81, 1) 0%,
    rgba(218, 84, 0, 1) 16%,
    rgba(16, 24, 88, 1) 69%
  );
  content: "";
}

.link {
  color: var(--dark-blue);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8125rem;
}

@media screen and (max-width: 768) {
  .headerLogo {
    width: 90px;
  }
  .button {
    height: 20px;
    font-size: 13px;
  }
}

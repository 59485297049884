.languageSelector {
  text-transform: uppercase;
  padding-left: 8px;
  padding-right: 8px;
}

.languageSelector, .languageSelector:focus {
  color: #02284F;
}

.languageSelector.selected > :global(span) {
  text-decoration: underline;
}

.languageSelector.loading, .icon.loading {
  color: #777777;
}

.icon {
  font-size: 1.25rem;
}
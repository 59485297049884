.greatTeam {
  text-align: center;
}

.greatTeam h2 {
  margin: 0 auto;
  max-width: 550px;
  color: var(--dark-blue);
  font-weight: 700;
  font-size: var(--heading-size-1);
  line-height: 1.2;
}

.highlight {
  font-weight: 300;
  font-size: var(--p-size-1);
  color: var(--dark-blue);
  line-height: 1.6;
}

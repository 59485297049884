@use "styles" as *;

.container {
  text-align: center;
  max-width: 290px;
  width: 100%;
  margin: 0 auto 24px;
  @include media-breakpoint-up(md) {
    position: absolute;
    right: 100%;
    bottom: 0;
    margin-right: 24px;
    text-align: left;
    margin-bottom: 0;
  }
}

.card {
  text-align: center;
}

.title {
  color: var(--primary-text);
  font-size: var(--heading-size-4);
  font-weight: 700;
}

.note {
  margin-top: 4px;
  color: var(--primary-text);
  font-size: var(--p-size-3);
  font-weight: 600;
}

.link,
.link:hover {
  margin-top: 4px;
  font-weight: 600;
  font-size: var(--p-size-5);
  display: inline-block;
  color: #ffffff;
  @include media-breakpoint-up(md) {
    position: absolute;
    top: 100%;
    margin-left: 8px;
  }
}
@use "styles" as *;

.hero {
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.4);
    content: "";
  }

  video {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.3s, transform 0s;

    object-fit: cover;
    object-position: center;
    &::-webkit-media-controls {
      display: none;
    }
  }
}

.quotingContainer {
  position: relative;
}

.content {
  position: relative;
  z-index: 2;
  padding: 34px 24px 60px;

  @include media-breakpoint-up(sm) {
    padding: 3rem 2.25rem 5.5rem;
  }
  @include media-breakpoint-up(md) {
    display: grid;
    align-items: flex-start;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    padding-top: 4.5rem;
    max-width: 91.66666667%;

    gap: 2rem;
    grid-template-columns: minmax(340px, 540px) minmax(350px, 480px);
  }
  @include media-breakpoint-up(lg) {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
    max-width: 83.33333333%;

    gap: 4rem;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 9.5rem;
  }
}

.container {
  position: relative;
  z-index: 2;
  margin-bottom: 1.5rem;

  @include media-breakpoint-up(sm) {
    margin-bottom: 2.5rem;
  }
}

.title,
.titleAnimate {
  margin-bottom: 0;
  color: #fff;
  letter-spacing: 0.025rem;
  font-weight: 700;
  font-size: var(--heading-size-2);
  line-height: 1.2;

  @include media-breakpoint-up(xs) {
    font-size: var(--heading-size-1);
  }
  @include media-breakpoint-up(sm) {
    font-size: 2.875rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 3rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 3.275rem;
  }

  @media screen and (min-width: 1440px) {
    font-size: 4rem;
  }
}

.titleAnimate {
  margin-bottom: 2rem;
  color: #fff;
  white-space: pre;
  font-weight: 400;
}

.typed-cursor {
  opacity: 1;
  animation: typedjsBlink 0.7s infinite;
}

.typed-fade-out {
  opacity: 0;
  transition: opacity 0.25s;
}

@keyframes typedjsBlink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

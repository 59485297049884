.landingBody h2 {
  color: var(--dark-blue);
  font-weight: 700;
  font-size: var(--heading-size-1);
  line-height: 1.2;
}

.landingBody h3 {
  color: var(--dark-blue);
  font-weight: 700;
  font-size: var(--heading-size-3);
}

.landingBody p {
  font-size: var(--p-size-2);
  color: var(--primary-text-color);
  margin: 0;
}

.floatingChat {
  position: absolute;
  top: 50%;
  right: 0;
  max-width: 476px;
  width: 66%;
}

.floatingContainer {
  position: absolute;
  bottom: 10%;
  right: 7.5%;
  width: 25%;
  max-width: 210px;
  transform: translateX(40px);
}

.floatingSupplier {
  position: absolute;
  top: 0%;
  left: 7.5%;
  width: 50%;
  max-width: 488px;
  transform: translateX(-30px);
}


.floatingTrack {
  position: absolute;
  top: 40%;
  left: 0;
  width: 33%;
  max-width: 275px;
}

.floatingSku {
  position: absolute;
  top: 0%;
  right: 3%;
  width: 80%;
  max-width: 631px;
  transform: translateX(40px);
}

.floatingDocs {
  position: absolute;
  top: 10%;
  left: 5%;
  width: 33%;
  max-width: 452px;
  transform: translateX(-30px);
}

.animated {
  opacity: 0;
  filter: blur(4px);
  transition: all 0.5s ease-in-out;
}

.visible {
  opacity: 1;
  filter: none;
  transform: none;
}

.imageContainer {
  position: relative;
  margin-bottom: 4rem;
}

.imageContainer::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 90%;
  background: var(--light-gray);
  border-radius: 24px;
}

.imageContainer:nth-child(2n)::before {
  left: auto;
  right: 90%;
}

.whatWillYouNotice {
  margin-top: 5.5rem;
}

.card {
  background-color: #ffffff;
  border-radius: 12px;
  font-size: var(--p-size-2);
  font-weight: 300;
  padding: 5rem 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardContent {
  max-width: 550px;
  text-align: center;
}

.card .content {
  margin-bottom: 36px;
}

.title {
  margin-bottom: 40px;
  color: var(--dark-blue);
  font-size: var(--heading-size-3);
}

.blueButton,
.orangeButton {
  font-weight: 700;
  border-radius: var(--border-radius-2);
  color: var(--primary-blue);
  font-size: var(--p-size-4);
  border-width: 2px;
  padding-left: 3rem;
  padding-right: 3rem;
  letter-spacing: 0.0125rem;
  height: 45px;
}

.blueButton {
  border-color: var(--primary-blue);
}

.orangeButton {
  border-color: var(--primary-orange);
  color: var(--primary-orange);
  /* this is so lighthouse doesn't fail on low contrast between font color and background */
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=);
}

.content {
  font-weight: 300;
  color: #1f4173;
  font-size: 17px;
  line-height: 32px;
}

@media screen and (max-width: 992px) {
  .card {
    padding: 2rem;
    margin-bottom: 25px;
  }
}
